<template>
  <div>
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />
    <v-inner-header
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :redirect="redirectPath"
    />
    <modal
      name="relatedProjects"
      classes="modal"
      width="100%"
      :max-width="420"
      height="auto"
      :adaptive="true"
    >
      <div class="flex px-2 justify-between">
        <div class="title">
          Related projects
        </div>
        <div
          class="close "
          @click="$modal.hide('relatedProjects')"
        />
      </div>

      <div class="flex px-2 -mt-1">
        <div class="w-full">
          {{ name }}
          <div
            v-if="relatedProjects.length > 0"
            class="mt-4"
          >
            <ul
              v-for="(item, index) in relatedProjects"
              :key="index"
            >
              <li
                class="mb-3"
              >
                <router-link
                  :to="`/projects/${item.id}`"
                  class="green underline"
                >
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <div
            v-else
            class="mt-4"
          >
            <router-link
              :to="`/`"
              class="green underline"
            >
              Create a new project
            </router-link>
          </div>
        </div>
      </div>
    </modal>
    <div class="w-full px-4 max-w-md mx-auto mt-8 mb-20">
      <div class="bg-white mt-24 shadow rounded px-8 pt-6 pb-8 mb-4">
        <v-row class="items-center mb-3 px-4">
          <v-col class="w-1/2">
            <h1 class="text-xl">
              Contact details
            </h1>
          </v-col>
          <v-col class="w-1/2 text-right">
            <a
              v-if="relatedProjects.length > 0"
              class="green underline cursor-pointer"
              @click="showRelatedProjectsModal(name, id)"
            >
              Related projects
            </a>
          </v-col>
        </v-row>


        <form
          method="post"
          autocomplete="off"
          @submit.prevent="submit"
        >
          <div class="input-group">
            <label for="name"> First and last name </label>
            <input
              id="name"
              v-model="name"
              v-validate="{ required: true }"
              name="name"
              type="text"
            >
            <span
              v-if="errors.has('name')"
              class="error"
            >{{ errors.first('name') }}</span>
          </div>
          <div class="input-group">
            <label for="email"> Email address </label>
            <input
              id="email"
              v-model.lazy.trim="email"
              v-validate="{ required: true, email: true }"
              name="email"
              type="text"
              @blur="checkEmail"
            >
            <span
              v-if="errors.has('email')"
              class="error"
            >{{ errors.first('email') }}</span>
          </div>
          <div class="input-group">
            <label for="phone"> Phone number </label>
            <input
              id="phone"
              v-model="phone"
              type="text"
            >
          </div>
          <div class="input-group">
            <label for="address"> Address </label>
            <input
              id="address"
              v-model="address"
              type="text"
            >
          </div>
          <div class="input-group">
            <label for="postal_code"> Postal code </label>
            <input
              id="postal_code"
              v-model="postal_code"
              type="text"
            >
          </div>

          <div class="input-group">
            <label for="city"> City </label>
            <input
              id="city"
              v-model="city"
              type="text"
            >
          </div>
          <div class="input-group">
            <label for="country"> Country </label>
            <div class="select-container">
              <select v-model="country">
                <option :value="null">
                  Select a country
                </option>
                <option
                  v-for="(item, index) in countriesList"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="input-group">
            <label for="company"> Company Name </label>
            <input
              id="company"
              v-model="company"
              type="text"
            >
          </div>
          <div class="input-group">
            <label for="vat"> VAT number </label>
            <input
              id="vat"
              v-model="vat"
              type="text"
            >
          </div>

          <div>
            <v-checkbox
              id="isSameAsBilling"
              v-model="isSameAsBilling"
              :checked="isSameAsBilling"
              label="Same as billing address"
            />
          </div>

          <hr class="mt-3 mb-5">

          <div
            v-if="!isSameAsBilling"
            class="mb-5"
          >
            <div class="input-group">
              <label for="billing_name">Billing First and last name </label>
              <input
                id="billing_name"
                v-model="billing_name"
                name="billing_name"
                type="text"
              >
            </div>
            <div class="input-group">
              <label for="billing_phone">Billing Phone number </label>
              <input
                id="billing_phone"
                v-model="billing_phone"
                type="text"
              >
            </div>
            <div class="input-group">
              <label for="billing_address">Billing Address </label>
              <input
                id="billing_address"
                v-model="billing_address"
                type="text"
              >
            </div>
            <div class="input-group">
              <label for="billing_city">Billing City </label>
              <input
                id="billing_city"
                v-model="billing_city"
                type="text"
              >
            </div>
            <div class="input-group">
              <label for="billing_postal_code">Billing Postal code </label>
              <input
                id="billing_postal_code"
                v-model="billing_postal_code"
                type="text"
              >
            </div>
            <div class="input-group">
              <label for="billing_country">Billing Country </label>
              <div class="select-container">
                <select v-model="billing_country">
                  <option :value="null">
                    Select a billing country
                  </option>
                  <option
                    v-for="(item, index) in countriesList"
                    :key="index"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="input-group">
            <label for="notes"> Notes (only visible to you) </label>
            <textarea v-model="notes" />
          </div>

          <div
            class="text-red underline cursor-pointer"
            @click="deleteModal"
          >
            Delete contact
          </div>
        </form>
      </div>
      <div class="text-right">
        <button
          class="green-btn"
          @click="save"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'

let cancelSource

export default {
  data(){
    return {
      metaTitle: 'Loading',
      isSaving: false,
      isSaved: false,
      loading: false,
    	id: this.$route.params.id,
    	is_company: false,
      isNewContact: true,
      contact: null,
      name: '',
      email: '',
      phone: '',
      address: '',
      postal_code: '',
      city: '',
      country: null,
      company: '',
      vat: '',
      notes:'',
      isSameAsBilling: true,
      countriesList: [],
      billing_name: '',
      billing_phone: '',
      billing_address: '',
      billing_postal_code: '',
      billing_city: '',
      billing_country: null,
      relatedProjects: 0,
      user: auth.user(),
      redirectPath: '/contacts',
    }
  },
  async mounted(){
    this.getCountriesList()

    if(this.$route.params.id){
      this.loading = true
      await this.getContact(this.$route.params.id)
      this.metaTitle = `Contact: ${this.name}`
      this.isNewContact = false
    } else {
      this.metaTitle = 'New contact'
    }

    if(this.$route.query.project){
      this.redirectPath = `/projects/${this.$route.query.project}`
    }
	},
  methods: {
    async showRelatedProjectsModal(name, id){

      cancelSource = createCancelSource()
        this.$modal.show('relatedProjects')

      // try {
      //   const { data } = await this.$api.get('contacts').load(id, {
      //     cancelToken: cancelSource.token
      //   })

      //   this.relatedProjects = data.contact.projects

      // } catch(e){
      //   logException(e)
      // }
    },
    async checkEmail(){

      this.emailError = false

      try {
        const { data } = await this.$api.get('contacts').checkEmail({ contactEmail: this.email,
          contactId: this.id
        })

        if(data.contact){
          this.emailError = true
          this.errors.add({
            field: 'email',
            msg: 'This email address is already in use.'
          })
        }
      } catch(e){
        logException(e)
      }
    },
    async deleteModal(){

      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete this contact?',
        text: 'Projects attached to the contact will also be deleted.',
      })
      if (canDelete) {
        try {
          const { data } = await this.$api.get('contacts').delete(this.id)

          this.$router.push('/contacts')

        } catch(e){
          logException(e)
        }
      }
    },
    async getCountriesList() {
      try {
        const { data } = await this.$api.get('country').all()
        this.countriesList = data.countries
      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    save() {
      this.$validator.validate().then(result => {
        if(result){
          this.doSave()
        }
      })
    },
    async doSave(){
      try {
        this.isSaving = true

        const { data } = await this.$api.get('contacts').save({
          companyId: this.user.me.company_id,
          contactId: this.id,
          contactName: this.name,
          contactEmail: this.email,
          contactPhone: this.phone,
          contactAddress: this.address,
          contactPostalCode: this.postal_code,
          contactCity: this.city,
          contactCountry: this.country,
          contactCompany: this.company,
          contactVat: this.vat,
          contactNotes: this.notes,
          isSameAsBilling: this.isSameAsBilling,
          billing_name: this.billing_name,
          billing_phone: this.billing_phone,
          billing_address: this.billing_address,
          billing_postal_code: this.billing_postal_code,
          billing_city: this.billing_city,
          billing_country: this.billing_country,
        })

        this.isSaved = true
        this.isSaving = false
        this.id = data.contact.id

        if(this.isNewContact){

          const postData = {
            entryType: 'add_first_contact',
          }

          try {
            await this.$store.dispatch('company/gamification', postData)
          } catch(e) {
            this.$toasted.global.api_error(e)
          }
          this.isNewContact = false
        }

        this.$toasted.global.general_success({
          message : 'Contact has been saved.'
        })

      } catch(e){
        logException(e)
      }
    },
    async getContact(id){

      cancelSource = createCancelSource()

      try {
        const { data } = await this.$api.get('contacts').load(id, {
          cancelToken: cancelSource.token
        })

        this.relatedProjects = data.contact.projects

        this.id = data.contact.id
        this.name = data.contact.full_name
        this.email = data.contact.email
        this.phone = data.contact.phone
        this.vat = data.contact.vat
        this.address = data.contact.address
        this.postal_code = data.contact.postal_code
        this.city = data.contact.city
        this.country = data.contact.country
        this.notes = data.contact.notes
        this.company = data.contact.company_name
        this.isSameAsBilling = data.contact.same_as_billing_address

        this.billing_name = data.contact.full_billing_name
        this.billing_phone = data.contact.billing_phone
        this.billing_address = data.contact.billing_address
        this.billing_city = data.contact.billing_city
        this.billing_country = data.contact.billing_country

        this.success = true

      } catch(e){
        this.$router.push('/contacts')
        logException(e)
      }
    }
  },
}
</script>
